import React from 'react'
import styled from '@emotion/styled'
import { Heading } from 'rebass'
import Flex from './Flex'

const SectionHeading = ({
  as = 'h2', children, action = null, ...props
}) => (
  <Flex
    {...props}
    as="header"
    flexWrap="wrap"
    alignItems="center"
    flexDirection="row"
    justifyContent="space-between"
  >
    <Heading
      as={as}
      py={1}
      fontSize={3}
      variant="caps"
    >
      {children}
    </Heading>
    {action}
  </Flex>
)

export default styled(SectionHeading)`
  margin-left: 0;

  h2 {
    margin-left: 0;
    font-size: 20px;
    margin-bottom: 0;
  }

  h2:after{
    content: "";
    margin-top: 5px;
    width: 44px;
    display: block;
    height: 3px;
    background-color: ${(props) => props.theme.colors.primary[50]};
  }
`
