import React from 'react'
import styled from '@emotion/styled'
import {
  List, ListItem, Link, Heading, Text,
} from '../ui'

const StyledListItem = styled(ListItem)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondary[100]};

  &:last-child {
    border-bottom: none;
  }
`

const PostListCompact = ({ posts }) => (
  <List
    my={-2}
    flexWrap="wrap"
    flexDirection="row"
  >
    {posts.map(({ title, uri, meta }) => (
      <StyledListItem
        py={2}
        width={1}
        key={uri}
      >
        <Link
          to={uri}
          variant="secondary"
        >
          <Heading
            as="h3"
            fontFamily={'Merriweather, serif'}
            fontSize={2}
            lineHeight={1.5}
            fontWeight="inherit"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Text
            mt={1}
            variant="description"
          >
            {meta}
          </Text>
        </Link>
      </StyledListItem>
    ))}
  </List>
)

export default PostListCompact
