import React from 'react'
import { Box, SectionHeading } from '../ui'

const LayoutSection = ({
  heading, action = null, headingAs, children, ...props
}) => (
  <Box
    mb={3}
    {...props}
  >
    {
      heading
        ? (
          <SectionHeading
            mt={3}
            mb={3}
            ml={0}
            as={headingAs}
            action={action}
          >
            {heading}
          </SectionHeading>
        ) : null
    }
    <Box pb={2}>
      {children}
    </Box>
  </Box>
)

export default LayoutSection
