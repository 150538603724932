import React from 'react'
import styled from '@emotion/styled'
import { Box, Button, LinkExternalSafe } from '../ui'
import downloadIcon from '../../data/images/icons/icon-download-light.svg'

const StyledButton = styled(Button)`
  height: 26px;
  line-height: 1;
  border-radius: 18px;
`

const DownloadEventsButton = () => (
  <StyledButton
    px={[2, 3]}
    fontSize={1}
    variant="accent"
    width="fit-content"
    marginTop="20px"
    display="flex"
    alignItems="center"
    title="Heti program"
    as={LinkExternalSafe}
    href={process.env.EVENTS_PDF_URL}
  >
<Box
      mr={2}
      as="span"
      display={'block'}
    >
      heti program
    </Box>
    <img
      alt=""
      as="img"
      width="14"
      height="14"
      src={downloadIcon}
    />
  </StyledButton>
)

export default DownloadEventsButton
